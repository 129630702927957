<template>
  <div class="main" style="position: relative">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="pc indexLayoutContent" style="padding: 0px">
          <div
            class="index page flex-col justify-start"
            style="position: relative"
          >
            <top-aen
              v-show="topshow"
              v-if="w750 == 0"
              ref="topa"
              :kind="`index`"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
              :bmshow="bmshow"
              @bmclose="bmclose"
              :bmstate="bmstate"
              :bmid="bmid"
              :bkind="bkind"
              @rlstate="rlstate"
              :selectlag="1"
            ></top-aen>

            <div class="mobile">
              <top-ben
                v-show="topshow"
                v-if="w750 == 1"
                ref="topb"
                :kind="`index`"
                :topbarhide="topbarhide"
                :tb="tb"
                :w750="w750"
                :topbarOpacity="topbarOpacity"
                :lag="lag"
                :bmshow="bmshow"
                @bmclose="bmclose"
                :bmstate="bmstate"
                :bmid="bmid"
                :bkind="bkind"
              ></top-ben>
            </div>

            <!--------------------------------------------------------------------------------------->
            <div
              class="group_1 flex-col windowheight"
              style="background-color: rgba(38, 56, 23, 1)"
            >
              <div
                v-if="clist90.length > 0"
                class="swiper sc0"
                :style="`background-image:url(${swiper0backimg});opacity:${
                  1 - opy1
                };opacity: 0;`"
              >
                <div class="swiper-wrapper">
                  <template v-for="(item, index) in clist90">
                    <div :class="`swiper-slide ssl0 ss${index}`" :key="index">
                      <div
                        :class="`mask mk0 ${index == 0 ? 'mkmaskshow' : ''}`"
                        :style="`background-image:url(${
                          baseUrl + item.imgurl
                        });`"
                        @click="
                          () => {
                            godetall(item.url, item.pname, item.kind, item.id);
                          }
                        "
                      ></div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="mkdots">
                <template v-for="(item, index) in clist90">
                  <div
                    :key="index"
                    :class="`md ${mdselectIndex == index ? 'mdselected' : ''}`"
                  ></div>
                </template>
              </div>
            </div>

            <div class="group_2 flex-row justify-center">
              <div class="group_2_in flex-row justify-between">
                <div class="group_2_1 flex-col">
                  <div
                    class="textitem wow fadeInLeft"
                    data-wow-delay="0s"
                    data-wow-duration="1s"
                    style="margin-top: 15rem"
                  >
                    <div
                      style="
                        width: 640px;
                        height: 1px;
                        border: 1px solid #979797;
                        margin-bottom: 40px;
                      "
                    ></div>
                    <p
                      class="ptitle wow fadeInLeft"
                      data-wow-delay="0.1s"
                      data-wow-duration="1s"
                    >
                      ALL FIELDS is a platform committed to the cultural
                      exchange of outdoor lifestyles.
                    </p>
                    <p
                      class="ptitle wow fadeInLeft"
                      data-wow-delay=".2s"
                      data-wow-duration="1s"
                    >
                      We offer a comprehensive range of services, including
                      <span class="orange">events</span>,
                      <span class="orange">products</span>, and
                      <span class="orange">information</span>, aimed at
                      providing enthusiasts with a one-stop-shop for sustainable
                      and tailored outdoor living experiences.
                    </p>

                    <h5
                      class="orangeh5 wow fadeInLeft"
                      data-wow-delay=".3s"
                      data-wow-duration="1s"
                      style="width: 161px"
                    >
                      Quye Alliance
                    </h5>
                    <p
                      class="ptext wow fadeInLeft"
                      data-wow-delay=".4s"
                      data-wow-duration="1s"
                    >
                      Leading the way in varied outdoor life experiences.
                    </p>

                    <div
                      class="orangeh5 wow fadeInLeft"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                      style="width: 140px"
                    >
                      Quye Goods
                    </div>
                    <p
                      class="ptext wow fadeInLeft"
                      data-wow-delay=".6s"
                      data-wow-duration="1s"
                    >
                      Uniting different community outdoor brands.
                    </p>

                    <div
                      class="orangeh5 wow fadeInLeft"
                      data-wow-delay=".7s"
                      data-wow-duration="1s"
                      style="width: 149px"
                    >
                      Quye Trends
                    </div>
                    <p
                      class="ptext wow fadeInLeft"
                      data-wow-delay=".8s"
                      data-wow-duration="1s"
                      style="margin-bottom: 50px"
                    >
                      Gathering the latest in outdoor fashion and cultural
                      movements.
                    </p>

                    <p
                      class="ptext wow fadeInLeft"
                      data-wow-delay=".9s"
                      data-wow-duration="1s"
                    >
                      Our ethos is centered around a deep appreciation for the
                      outdoor lifestyle, with a focus <br />
                      on fostering a harmonious relationship between people and
                      nature.
                    </p>
                    <p
                      class="ptext wow fadeInLeft"
                      data-wow-delay="1s"
                      data-wow-duration="1s"
                    >
                      We inspire individuals to courageously expand their
                      horizons and carve out <br />
                      their distinctive outdoor lifestyle.
                    </p>
                  </div>
                </div>
                <div class="group_2_2 flex-col">
                  <img
                    class="text_12 wow fadeInUp"
                    data-wow-delay="0s"
                    data-wow-duration="1.5s"
                    src="https://cdn.moheweb.com/vdb/lweb/imgs/allfields1.png"
                    style="width: 82.9rem; height: 12.8rem"
                  />
                </div>
              </div>
              <div
                class="backimg"
                style="
                  opacity: 0.1;
                  background-position: right 80%;
                  background-attachment: local;
                "
              ></div>
            </div>

            <!--------------------------------------------------------------------------------------->

            <div class="group_3 flex-col">
              <div class="g3 flex-col" :style="`opacity:${1 - opy3};`">
                <div
                  class="entitle wow fadeInLeft"
                  data-wow-delay=".5s"
                  data-wow-duration=".5s"
                >
                  Our Competitive Edge
                </div>

                <div
                  class="freeDiv fdc fd1 wow fadeInLeft"
                  data-wow-delay=".5s"
                  data-wow-duration=".5s"
                >
                  <div
                    style="
                      font-weight: 500;
                      font-size: 40px;
                      color: #ffffff;
                      line-height: 40px;
                      text-align: left;
                      font-style: normal;
                      text-transform: uppercase;
                      margin-bottom: 25px;
                    "
                  >
                    Venue Network
                  </div>
                  <div
                    style="
                      width: 340px;
                      height: 1px;
                      border: 2px solid #f7601f;
                      margin-bottom: 25px;
                    "
                  ></div>
                  <div class="fd1t">
                    Strategically located in
                    <span class="orange">Shanghai</span>,
                    <span class="orange">Jiaxing</span>,
                    <span class="orange">Hangzhou</span>,
                    <span class="orange">Ningbo</span>, and other regions within
                    the Yangtze River Delta, we create a unique synergy between
                    urban commerce and the great outdoors.
                  </div>
                </div>

                <div
                  class="freeDiv fdc fd2 wow fadeInDown"
                  data-wow-delay=".5s"
                  data-wow-duration="1s"
                >
                  <div class="fdimg fdi0"></div>
                  <div class="fdimg fdi1"></div>
                  <div class="fdimg fdi2"></div>
                </div>

                <!-- <span
                  class="wow fadeInLeft"
                  data-wow-delay=".5s"
                  data-wow-duration="1s"
                > -->
                <div class="freeDiv fdc fd3">
                  <template v-for="(item, index) in clist9100">
                    <div
                      :key = 'index'
                      :class="`logo l${index} wow fadeInLeft`"
                      :style="{ backgroundImage: `url(${ baseUrl + item.imgurl})` }"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    ></div>
                  </template>
                  <!-- <div
                    class="logo l1 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l2 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l3 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l4 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l5 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l6 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l7 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l8 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l9 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l10 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l11 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="logo l12 wow fadeInLeft"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div> -->
                </div>
                <!-- </span> -->

                <div class="freeDiv fdc fd4">
                  <div
                    style="
                      font-weight: 500;
                      font-size: 40px;
                      color: #ffffff;
                      line-height: 40px;
                      text-align: left;
                      font-style: normal;
                      text-transform: uppercase;
                      margin-bottom: 25px;
                    "
                    class="wow fadeInRight"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  >
                    Brand Conglomerate
                  </div>
                  <div
                    style="
                      width: 340px;
                      height: 1px;
                      border: 2px solid #f7601f;
                      margin-bottom: 25px;
                    "
                    class="wow fadeInRight"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  ></div>
                  <div
                    class="fd1t wow fadeInRight"
                    data-wow-delay=".5s"
                    data-wow-duration="1s"
                  >
                    We've established
                    <span class="orange"
                      >a network of local and international brands</span
                    >, amalgamating resources and strategies from various brand
                    segments to generate a mutually beneficial and expansive
                    impact.
                  </div>
                </div>

                <div
                  class="freeDiv fdc fd5 wow fadeInLeft"
                  data-wow-delay=".5s"
                  data-wow-duration="1s"
                >
                  <div
                    style="
                      font-weight: 500;
                      font-size: 40px;
                      color: #ffffff;
                      line-height: 40px;
                      text-align: left;
                      font-style: normal;
                      text-transform: uppercase;
                      margin-bottom: 25px;
                    "
                  >
                    Holistic Marketing
                  </div>
                  <div
                    style="
                      width: 340px;
                      height: 1px;
                      border: 2px solid #f7601f;
                      margin-bottom: 25px;
                    "
                  ></div>
                  <div class="fd1t">
                    Our target audiences are engaged through an all-encompassing
                    strategy that includes
                    <span class="orange"
                      >content marketing, cross-sector collaborations, a broad
                      social network</span
                    >, and <span class="orange">influencer engagement.</span>
                  </div>
                </div>

                <div
                  class="freeDiv fdc fd6 wow fadeInRight"
                  data-wow-delay=".5s"
                  data-wow-duration="1s"
                >
                  <div class="fdimg fdi3"></div>
                  <div class="fdimg fdi4"></div>
                  <div class="fdimg fdi5"></div>
                </div>

                <!-- <div class="zsqlist">
                  <div class="zsqitem zi0 zsqselected"></div>
                  <div class="zsqitem zi1"></div>
                  <div class="zsqitem zi2"></div>
                  <div class="zsqitem zi3"></div>
                  <div class="zsqitem zi4"></div>
                  <div class="zsqitem zi5"></div>
                  <div class="zsqitem zi6"></div>

                  <div class="icon_arrow_botttom"></div>
                </div> -->
              </div>
            </div>

            <div class="group_4">
              <div class="g4 flex-col">
                <div class="g4-back"></div>

                <div
                  class="entitle wow fadeInLeft"
                  data-wow-delay="0s"
                  data-wow-duration="1s"
                >
                  ALL FIELDS in 2023
                </div>

                <div
                  class="fd1 wow fadeInLeft"
                  data-wow-delay=".2s"
                  data-wow-duration="1s"
                >
                  <div
                    style="
                      width: 40rem;
                      height: 1px;
                      border: 1px solid #979797;
                      margin-bottom: 20px;
                      margin-top: 40px;
                      margin-left: 0px;
                    "
                  ></div>

                  <div class="ptitle">
                    ALL FIELDS has enhanced the Shanghai Sports Show by curating
                    a special exhibit dedicated to outdoor lifestyles.
                  </div>

                  <div class="ptext">
                    Together with the Show, and using travel as a connecting
                    theme, we integrated six key outdoor life scenes:
                    <span class="orange">rugged off-roading</span>,
                    <span class="orange">urban camping</span>,
                    <span class="orange">cycling, water sports</span>, board
                    <span class="orange">sports</span>, and fishing, offering an
                    immersive experience to visitors. Through hands-on practices
                    and interactions, we enabled visitors to gain a
                    comprehensive understanding of various outdoor sports, their
                    required gear, techniques, safety information, and
                    overarching principles, thereby advancing the culture and
                    way of life associated with outdoor sports.
                  </div>
                </div>

                <div class="fd3">
                  <div
                    class="st wow fadeInLeft"
                    data-wow-iteration="1"
                    data-wow-delay=".3s"
                    data-wow-duration="1s"
                  >
                    <div class="fd2">
                      Exhibition Highlights
                      <img style="width: 26px" src="../assets/limgsen/jt.png" />
                    </div>
                  </div>
                  <div class="list">
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">6+1</div>
                      <div class="orgline"></div>
                      <div class="text">A first-of-its-kind</div>
                      <div class="text">6+1 outdoor</div>
                      <div class="text">sports lifestyle</div>
                      <div class="text">display and</div>
                      <div class="text">interaction zone</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">7000</div>
                      <div class="orgline"></div>
                      <div class="text">Attraction of</div>
                      <div class="text">nearly 70,000</div>
                      <div class="text">sports and</div>
                      <div class="text">outdoor</div>
                      <div class="text">aficionados</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">200</div>
                      <div class="orgline"></div>
                      <div class="text">Participation of</div>
                      <div class="text">close to 200</div>
                      <div class="text">exhibitors</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">30</div>
                      <div class="orgline"></div>
                      <div class="text">Showcasing over</div>
                      <div class="text">30 outdoor brands</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">20+</div>
                      <div class="orgline"></div>
                      <div class="text">More than 20 new</div>
                      <div class="text">product debuts</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">77</div>
                      <div class="orgline"></div>
                      <div class="text">Presence of 77</div>
                      <div class="text">industry</div>
                      <div class="text">luminaries</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">56</div>
                      <div class="orgline"></div>
                      <div class="text">Alongside 56</div>
                      <div class="text">professional</div>
                      <div class="text">forums and</div>
                      <div class="text">knowledge-</div>
                      <div class="text">sharing events</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">20</div>
                      <div class="orgline"></div>
                      <div class="text">Organization of nearly</div>
                      <div class="text">20 charitable</div>
                      <div class="text">interactions that</div>
                      <div class="text">delved into sustainable</div>
                      <div class="text">development</div>
                      <div class="text">principles</div>
                    </div>
                    <div
                      class="item wow fadeInLeft"
                      data-wow-iteration="1"
                      data-wow-delay=".5s"
                      data-wow-duration="1s"
                    >
                      <div class="t">56<span>MM</span></div>
                      <div class="orgline"></div>
                      <div class="text">Achieving an</div>
                      <div class="text">impressive online</div>
                      <div class="text">reach of over 150</div>
                      <div class="text">million views</div>
                    </div>
                  </div>
                </div>

                <div class="fd4">Wonderful moment</div>

                <div class="swiper sc4" style="opacity: 0">
                  <div class="swiper-wrapper">

                  <template v-for="(item, index) in clist9200">
                    <div :class="`swiper-slide ss${index}`" :key="index" :style="{backgroundImage:`url(${baseUrl + item.imgurl})`}">
                      <div class="sc4zz"></div>
                    </div>
                  </template>
                  </div>
                </div>

                <div class="swiper2bardiv">
                  <span class="sc2index sc2startindex">01</span>
                  <div class="sc2bardiv">
                    <div class="sc2bar"></div>
                  </div>
                  <span class="sc2index sc2finalindex">06</span>
                </div>
              </div>
            </div>

            <div class="group_5">
              <div class="g5 flex-col">
                <div class="g5-back"></div>

                <div
                  class="entitle wow fadeInRight"
                  data-wow-delay="0s"
                  data-wow-duration="1.5s"
                  style="
                    color: #000;
                    left: auto;
                    right: 80px;
                    position: absolute;
                  "
                >
                  Our Vision for 2024
                </div>

                <div
                  class="fd1 wow fadeInLeft"
                  data-wow-delay=".2s"
                  data-wow-duration="1.5s"
                >
                  <div
                    style="
                      width: 64rem;
                      height: 1px;
                      border: 1px solid #979797;
                      margin-bottom: 20px;
                      margin-top: 40px;
                      margin-left: 0px;
                    "
                  ></div>

                  <div class="ptext">
                    We are gearing up to create
                    <span class="orange"
                      >showcases, pop-up stores, activities, and provide
                      up-to-the-minute information on outdoor life</span
                    >. Our target demographic includes
                    <span class="orange"
                      >solo explorers, friends with shared interests, romantic
                      couples, colleagues, and families enjoying quality time
                      together</span
                    >.
                  </div>
                </div>

                <div
                  class="fd2 wow fadeInLeft"
                  data-wow-delay=".5s"
                  data-wow-duration="1.5s"
                >
                  Here,<br />
                  You can enter the circle quickly and easily, do not have to
                  cross thousands of mountains and rivers, but with a more
                  comfortable outdoor experience.<br />
                  You can release stress, perceive nature with the most relaxed
                  mind, and return to social life in a more positive state.<br />
                  You can develop new skills, stimulate physical strength, and
                  feel the progressive power of life.<br />
                  You can explore the mysteries of ecology, regain curiosity and
                  respect for nature, follow the way of ecology, and pass on the
                  concept of harmony.<br />
                  You can temper your character, be confident, brave,
                  independent, strong, and united, and become a more brilliant
                  self.<br />
                  You can strengthen your relationships in a stress-free
                  environment and the emotions of family and friends can quickly
                  heat up.
                </div>
                <!-- 
                <span
                  class="wow fadeIn"
                  data-wow-iteration="1"
                  data-wow-delay=".5s"
                  data-wow-duration="1s"
                > -->
                <div class="fd3">
                  <div class="item">
                    <div class="icon icon1"></div>
                    <div class="it">Hiking</div>
                  </div>
                  <div class="item">
                    <div class="icon icon2"></div>
                    <div class="it">Off-Roading</div>
                  </div>
                  <div class="item">
                    <div class="icon icon3"></div>
                    <div class="it">cycling</div>
                  </div>
                  <div class="item">
                    <div class="icon icon4"></div>
                    <div class="it" style="width: 18.6rem">Water Sports</div>
                  </div>
                  <div class="item">
                    <div class="icon icon5"></div>
                    <div class="it">Fishing</div>
                  </div>
                </div>
                <!-- </span> -->
                <div class="fd4"></div>
              </div>
            </div>

            <div class="group_6">
              <div class="g6">
                <div class="entitle">Learn more ALL FIELDS</div>
                <div class="p6download" id="showdownloaddiv">Download</div>
              </div>
            </div>

            <!--------------------------------------------------------------------------------------->

            <footer-aen
              v-if="w750 == 0"
              :kind="`mindex`"
              :g8top="g8top"
              :g8left="g8left"
              :lag="lag"
            ></footer-aen>

            <div class="downloaddiv" v-if="downloaddivshow">
              <div class="close" id="closedownloaddiv"></div>
              <div class="downloadlist">
                <div class="title">Download list</div>
                <div class="list">
                  <template v-for="(item, index) in clist9000">
                    <div
                      class="item"
                      @click="downloadfile(baseUrl + item.imgurl)"
                      :key="index"
                    >
                      <div class="t">{{ item.pname }}</div>
                      <div class="downicon"></div>
                    </div>
                  </template>
                  <div class="item"></div>
                </div>
              </div>
            </div>

            <!-- <div class="zzp">
              <img
                class="loadlogo"
                referrerpolicy="no-referrer"
                src="../assets/limgs/logo.png"
              />
              <img class="loading" src="../assets/limgs/loading.png" />
              <div class="backimg"></div>
            </div> -->
          </div>
        </a-layout-content>

        <span class="mobile">
          <footer-ben
            :kind="`mindex`"
            :g8top="g8top"
            :g8left="g8left"
            :lag="lag"
          ></footer-ben>
        </span>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchCase, fetchFriend, fetchBase, checkMember } from "../api/index";
import FooterIndex from "../components/FooterIndex.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Pagination,
  Mousewheel,
  Scrollbar,
  EffectFade,
  EffectCube,
  Duration,
} from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
Swiper.use([
  FreeMode,
  Autoplay,
  Pagination,
  Mousewheel,
  Scrollbar,
  EffectFade,
  EffectCube,
  Duration,
]);

import global from "../common";
import $ from "jquery";
// import FooterBar from '../components/FooterBar.vue';
import FooterAen from "../components/FooterAen.vue";
import FooterBen from "../components/FooterENB.vue";
import TopAen from "../components/TopAen.vue";
import TopBen from "../components/TopBen.vue";

import { WOW } from "wowjs";

export default {
  name: "index",
  components: {
    FooterAen,
    FooterBen,
    TopAen,
    TopBen,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      topshow: 1,
      g7selectindex: 0,
      mselectindex: 0,
      bkind: "0",
      bmshow: 0,
      bmstate: false,
      bmid: "0",
      clist9000: [],
      clist9100: [],
      clist9200: [],
      clist90: [],
      clist91: [],
      flist10: [],
      flist11: [],

      clist20: [],

      logoindex: "0",
      slshow: false,
      gglist: [
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/guanggao.png",
        },
      ],
      clist31: [],
      clist33: [],
      data33: null,
      clist40: [],
      lslist: [
        {
          title: "FM.看看世界",
          title2: "出行攻略",
          des: "趣野带你看世界-我们提供新鲜户外资讯，精选音乐/电影/书籍 等兴趣清单，还有各类好物/集合店指南，更多户外生活方式等你来发现！",
          id: "0",
          kind: "00",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back00.png",
        },
        {
          title: "趣野故事会",
          title2: "趣野故事会",
          des: "你也喜欢看故事么？此栏目应该很适合你-这里集合户外、任务、品牌故事",
          id: "0",
          kind: "01",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back01.png",
        },
        {
          title: "趣野视角",
          title2: "趣野视角",
          des: "带着趣野态度，从原创视角出发，给你带来系列精彩内容：可持续、出行方式、趣路亚、运动旅行、2024奥运会、中国户外文化",
          id: "0",
          kind: "02",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back02.png",
        },
      ],
      swiper0backimg: "",

      mdselectIndex: 0,
      splist: [],
      zlist: [],
      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper6: null,

      lswiper01: null,
      lswiper0: null,
      lswiper1: null,
      lswiper2: null,
      lswiper3: null,
      lswiper4: null,
      lswiper5: null,
      lswiper6: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        {
          id: "0",
          title: "趣野风向",
          url: "",
          class: "jianjie",
          list: [
            {
              title: "FM.看看世界",
              des: "了解趣野历史相关活动",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0bbd5b52752e40d7bd05e634f2ff35a3_mergeImage.png",
              id: "00",
            },
            {
              title: "趣野故事会",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfde342438c247fab8fca0fa5adc4e4889cb2b837a5debc0f0799d0f0f5d3c4c8",
              id: "01",
            },
            {
              title: "趣野视角",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng76c7ee71154fce1d4550cbe476a1aa62bd0a0fa0efff11576097faa2e329bc11",
              id: "02",
            },
          ],
        },
        {
          id: "1",
          title: "趣野伙伴",
          url: "",
          class: "huoban",
          list: [
            {
              title: "KOL日常",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "10",
            },
            {
              title: "合作品牌",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "11",
            },
          ],
        },
        {
          id: "2",
          title: "趣野好物（开发中）",
          url: "",
          class: "fuwuneirong",
          list: [
            {
              title: "好物推荐（开发中）",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "20",
            },
            {
              title: "购物平台（开发中）",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "21",
            },
          ],
        },
        {
          id: "3",
          title: "趣野SHOW",
          url: "",
          class: "anli",
          list: [
            {
              title: "现场SHOW什么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "30",
            },
            {
              title: "新鲜SHOW闻",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/efed9ea5f9d94fe999ad67d5274954b7_mergeImage.png",
              id: "31",
            },
            {
              title: "Ta们在SHOW",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cc8ff4aedae34e61931b49962afb8a2c_mergeImage.png",
              id: "32",
            },
            {
              title: "想来SHOW一下么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f5f6ac4ceb9e488f9a8a4bde7d2b358f_mergeImage.png",
              id: "33",
            },
          ],
        },
        {
          id: "4",
          title: "趣野CLUB",
          url: "",
          class: "lianxiwomen",
          list: [
            {
              title: "趣野人集合",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "40",
            },
            {
              title: "精彩回放",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "41",
            },
          ],
        },
      ],
      sectionindex: 0,
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      wheelTop: 0,
      cl31selectindex: -99,

      downloaddivshow: 0,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);

    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    const that = this;

    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 40,
      mobile: true,
      live: true,
    });
    wow.init();

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    global.setIndexDclass("");
    global.setIndexscroll(0);
    console.log("this.$el:", this.$el);
    that.handleScroll;
    window.addEventListener("scroll", that.handleScroll, true);
    // that.resizepage();
    if (!that.resizepage()) {
      return false;
    }

    window.onresize = () => {
      that.handleResize();
    };

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", "#closedownloaddiv", function () {
        console.log("close");
        // $(".downloaddiv").hide();
        that.downloaddivshow = 0;
      });
      $(document).on("click", "#showdownloaddiv", function () {
        that.downloaddivshow = 1;
      });
    }, 1000);

    that.fetch90();
    that.fetch9000();
    that.fetch9100();
    that.fetch9200();

    that.fetchb();

  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    const that = this;
  },
  methods: {
    downloadfile(url) {
      window.open(url);
    },
    myswiper2() {
      let that = this;

      setTimeout(() => {
        if (that.w750 == 0) {
          this.swiper2 = new Swiper(".sc4", {
            slidesPerView: 3,
            spaceBetween: 20,
            // freeMode: true,
            on: {
              transitionEnd: function () {
                let length = that.clist9200.length;
                let w =
                  parseInt(((parseInt(this.realIndex) + 3) / length) * 1000) / 10;
                $(".sc2bar").css({ width: w + "%" });
              },
              init: function () {
                let length = that.clist9200.length;
                let w = parseInt((3 / length) * 1000) / 10;
                $(".sc2bar").css({ width: w + "%" });
                $(".sc4").css({ opacity: "1" });
                if(length<10){
                  length = `0${length}`;
                }
                $(".sc2finalindex").html(length);
              },
            },
          });
        } else {
          this.swiper2 = new Swiper(".sc4", {
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: true,
            // freeMode: true,
            on: {
              transitionEnd: function () {
                let w =
                  parseInt(((parseInt(this.realIndex) + 1) / 6) * 1000) / 10;
                $(".sc2bar").css({ width: w + "%" });
              },
              init: function () {
                let w = parseInt((1 / 6) * 1000) / 10;
                $(".sc2bar").css({ width: w + "%" });
                $(".sc4").css({ opacity: "1" });
              },
            },
          });
        }
      }, 500);
    },

    clist31itemover(index) {
      console.log(index);
      this.cl31selectindex = index;
    },
    clist31itemleave() {
      this.cl31selectindex = -99;
    },
    takeprice(price) {
      let pstr = "";
      if (price.split(".").length > 1) {
        if (price.split(".")[1].length < 2) {
          pstr = "0";
          return String(price) + pstr;
        }
      } else {
        return String(price) + ".00";
      }
    },
    g7itemover(index) {
      this.g7selectindex = index;
    },
    addwheel() {
      // let that = this;
      // var dom = document.querySelector(".page");
      // if (window.addEventListener) {
      //   dom.addEventListener("wheel", that.loadWheel);
      // } else {
      //   dom.attachEvent("onmousewheel", that.loadWheel);
      // }
    },

    removewheel() {
      // let that = this;
      // var dom = document.querySelector(".page");
      // if (window.addEventListener) {
      //   dom.removeEventListener("wheel", that.loadWheel);
      // }
    },

    bmopen() {
      this.bmshow = 1;
      this.bmstate = true;
      this.bmid = "0";
      this.bkind = "0";
      this.removewheel();
      console.log("this.bmshow:", this.bmshow);
    },
    bmclose(id) {
      this.addwheel();
      console.log("bmclose");
      this.bmshow = 0;
      this.bmstate = true;
      this.bmid = id;

      console.log("this.bmshow:", this.bmshow);
    },
    rlstate(state) {
      if (state == 1) {
        this.removewheel();
      } else if (state == 0) {
        this.addwheel();
      }
    },
    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
        }
      }
    },
    slclose() {
      this.slshow = false;
    },
    setlogoindex(index) {
      this.logoindex = index;
      this.slshow = true;
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },

    fetch90(
      params = {
        show: 1,
        hot: 1,
        kind: 900,
        pagesize: 99,
        query: { mobile: 0 },
      }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist90 = res.results.list;
        }
        console.log("clist90:", that.clist90);

        that.myswiper0();
      });
    },

    fetch9000(params = { show: 1, kind: 9000, pagesize: 9999 }) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist9000 = res.results.list;
        }
        console.log("clist9000:", that.clist9000);
      });
    },

    fetch9100(params = { show: 1, kind: 9100, pagesize: 9999 }) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist9100 = res.results.list;
        }
        console.log("clist9100:", that.clist9100);
      });
    },
    fetch9200(params = { show: 1, kind: 9200, pagesize: 9999 }) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist9200 = res.results.list;
        }
        console.log("clist9200:", that.clist9200);

    that.myswiper2();
      });
    },

    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
      });
    },
    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase({}).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },

    showdetall(kind, id) {
      this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    sw3SlideTo(index, id) {
      console.log(index, id);

      if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/adetall/${d}/en`);
        } else {
          this.$router.push(`/adetall/${id}`);
        }
        return false;
      }
      let that = this;
      that.swiper3.slideTo(index, 0);
      $(".showawardsdiv").css({ left: "0%" });
      $(".showawardsdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },

    myswiper0() {
      let that = this;

      console.log("myswiper0:", that.clist90);
      $(".sc0zb").show();
      that.swiper0backimg =
        that.baseUrl + that.clist90[that.clist90.length - 1].imgurl;
      setTimeout(() => {
        that.swiper0backimg = that.baseUrl + that.clist90[0].imgurl;
        $(".ss0 .mask").removeClass("mkmaskshow");
        $(".sc0zb").hide();
      }, 2000);

      setTimeout(() => {
        this.swiper0 = new Swiper(".sc0", {
          effect: "fade",
          speed: 1000,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          init: function (swiper) {},
          on: {
            slideChange: function () {
              $(".sc0zb").show();
              $(".ss" + this.activeIndex + " .mk0").addClass("mkmaskshow");
              that.mdselectIndex = this.activeIndex;
              let thisimgurl = that.clist90[this.activeIndex].imgurl;
              setTimeout(() => {
                $(".sc0 .mask").removeClass("mkmaskshow");
                that.swiper0backimg = that.baseUrl + thisimgurl;
                $(".sc0zb").hide();
              }, 2000);
            },
          },
        });

        $(".sc0").css({ opacity: "1" });
      }, 10);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
        // this.$router.replace(`/indexm/${this.lag}`);
        // window.location.href=`#/indexm/${this.lag}`
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        // return false;
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/index/${this.lag}`;
      }

      setTimeout(() => {
        $(".zzp").fadeTo(1000, 0, function () {
          $(".zzp").hide();
        });
      }, 1500);
      return true;
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${this.pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      $("html").css({ fontSize: this.pageWidth / 192 });

      console.log("pageWidth:", this.pageWidth);
      console.log("fontSize:", this.pageWidth / 192);

      return this.resizeViewport();

      // this.recountHsize(this.winHeight); //XG
    },
    handleScroll(e) {
      // console.log("e.target.scrollTop:", window.scrollY);
      e.preventDefault();

      if (window.scrollY > window.innerHeight) {
        this.topshow = false;
      } else {
        this.topshow = true;
      }

      // if (this.alive) {
      //   const winHeight =
      //     e.target.scrollTop || document.documentElement.scrollTop;
      //   console.log("e.target.scrollTop:", e.target.scrollTop);
      //   global.setIndexscroll(winHeight);

      //   // this.recountHsize(winHeight); //XG

      //   // console.log("Scroll:", winHeight);
      // }
    },

    gotourl(url, kind) {
      this.$router.push(`/${url}/${kind}`);
    },

    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }

      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>

<style scoped>
.sc4zz {
  position: relative;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.sc4zz:hover{
  background-color: rgba(0, 0, 0, 0);
}
.ptitle {
  font-family: "SourceHanSansCN-Medium";
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  line-height: 38px;
  text-align: justify;
  font-style: normal;
}
.ptitle .orange {
  color: #f7601f;
  font-size: 30px;
  line-height: 38px;
}
.pc .group_2_1 .textitem {
  width: 132rem;
}

.pc .orangeh5 {
  width: auto;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  background: #f7601f;
  text-align: center;
  margin-bottom: 4px;
}

.pc .ptext {
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 34px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
}

.pc .group_2 {
  /* height: 970px; */
  height: 1040px;
}
.pc .group_2_2 .text_12 {
  top: 80vh;
}

.pc .group_3 {
  /* height: 970px; */
  height: 2020px;
  background-image: url(../assets/limgsen/group3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.pc .g3 {
  background-image: url(../assets/limgsen/group_3_t.png);
}

.pc .entitle {
  height: 80px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN-Medium;
  font-weight: 500;
  font-size: 80px;
  color: #ffffff;
  line-height: 80px;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  margin-left: 80px;
  margin-top: 80px;
}

.pc .freeDiv {
  position: absolute;
  z-index: 1;
}
.pc .fdc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pc .fdr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.pc .fd1 {
  width: 54rem;
  top: 390px;
  left: 120px;
}
.pc .fd1t {
  font-weight: 400;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 36px;
  text-align: left;
  font-style: normal;
}

.pc .fd1t .orange {
  font-size: 24px;
  line-height: 36px;
  color: #f7601f;
}

.pc .fd2 {
  width: 83rem;
  height: 50.5rem;
  top: 278px;
  right: 120px;
}

.pc .fdimg {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pc .fdi0 {
  width: 41.1rem;
  height: 30.8rem;
  background-image: url(../assets/limgsen/g3_0.png);
}
.pc .fdi1 {
  width: 27.4rem;
  height: 20.6rem;
  right: 0px;
  top: 14rem;
  background-image: url(../assets/limgsen/g3_1.png);
}
.pc .fdi2 {
  bottom: 0px;
  left: 31.5rem;
  width: 22.7rem;
  height: 17rem;
  background-image: url(../assets/limgsen/g3_2.png);
}

.pc .fd3 {
  width: 74.2rem;
  height: 29.5rem;
  top: 978px;
  left: 160px;
  /* background-color: rgba(255, 255, 255, 0.5); */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}
.pc .fd3 .logo {
  width: 17.2rem;
  height: 8.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* .pc .fd3 .l1 {
  background-image: url(../assets/limgsen/g3_logo1.png);
}
.pc .fd3 .l2 {
  background-image: url(../assets/limgsen/g3_logo2.png);
}
.pc .fd3 .l3 {
  background-image: url(../assets/limgsen/g3_logo3.png);
}
.pc .fd3 .l4 {
  background-image: url(../assets/limgsen/g3_logo4.png);
}
.pc .fd3 .l5 {
  background-image: url(../assets/limgsen/g3_logo5.png);
}
.pc .fd3 .l6 {
  background-image: url(../assets/limgsen/g3_logo6.png);
}
.pc .fd3 .l7 {
  background-image: url(../assets/limgsen/g3_logo7.png);
}
.pc .fd3 .l8 {
  background-image: url(../assets/limgsen/g3_logo8.png);
}
.pc .fd3 .l9 {
  background-image: url(../assets/limgsen/g3_logo9.png);
}
.pc .fd3 .l10 {
  background-image: url(../assets/limgsen/g3_logo10.png);
}
.pc .fd3 .l11 {
  background-image: url(../assets/limgsen/g3_logo11.png);
}
.pc .fd3 .l12 {
  background-image: url(../assets/limgsen/g3_logo12.png);
} */

.pc .fd4 {
  width: 54rem;
  top: 1000px;
  right: 150px;
}

.pc .fd5 {
  width: 54rem;
  top: 1500px;
  left: 120px;
}

.pc .fd6 {
  width: 83.2rem;
  height: 46.8rem;
  top: 1378px;
  right: 120px;
}

.pc .fdimg {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pc .fdi3 {
  width: 25.2rem;
  height: 25.2rem;
  top: 5rem;
  background-image: url(../assets/limgsen/g3_3.png);
}
.pc .fdi4 {
  width: 32rem;
  height: 32rem;
  right: 23rem;
  top: 14rem;
  background-image: url(../assets/limgsen/g3_4.png);
}
.pc .fdi5 {
  top: 0px;
  right: 0rem;
  width: 20rem;
  height: 20rem;
  background-image: url(../assets/limgsen/g3_5.png);
}

.pc .group_4 {
  min-height: 1400px !important;
}
.pc .g4 {
  height: 1820px !important;
  min-height: 1820px !important;
  background-color: #2a381b;
}
.pc .g4 .g4-back {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../assets/limgsen/group_3_t.png);
}

.pc .g4 .fd1 {
  position: absolute;
  width: 140rem;
  left: 80px;
  top: 140px;
}

.pc .g4 .ptitle {
  font-family: SourceHanSansCN-Normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  line-height: 38px;
  text-align: justify;
  font-style: normal;
  margin-bottom: 40px;
}

.pc .g4 .ptext {
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 34px;
  text-align: left;
  font-style: normal;
}

.pc .g4 .orange {
  width: auto;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  font-size: 24px;
  color: #f7601f;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  /* background: #f7601f; */
  text-align: center;
}

.pc .g4 .fd2 {
  width: 44rem;
  height: 32px;
  font-weight: 500;
  font-size: 2.6rem;
  color: #ffffff;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  transform: rotate(90deg);
  position: relative;
  border-top: 2px solid #f7601f;
  line-height: 2;
  top: 19rem;
  right: 19rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pc .g4 .fd3 {
  position: absolute;
  width: 183rem;
  height: 48rem;
  top: 558px;
  left: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* flex-wrap: wrap;
  align-content: space-between; */
  overflow: hidden;
}
.pc .g4 .fd3 .st {
  position: relative;
  width: 5.6rem;
  height: 44rem;
}
.pc .g4 .fd3 .list {
  position: relative;
  width: 183rem;
  height: 44rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-y: hidden;
}
.pc .g4 .fd3 .item {
  position: relative;
  width: 30rem;
  height: 44rem;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 4rem;
  padding-top: 5rem;
}

.pc .g4 .fd3 .item .t {
  font-weight: 800;
  font-size: 7.6rem;
  color: #ffffff;
  line-height: 6.8rem;
  margin-bottom: 2.4rem;
  text-align: left;
  font-family: "SourceHanSansCN-Normal";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
}
.pc .g4 .fd3 .item .orgline {
  width: 6rem;
  height: 0.8rem;
  margin-bottom: 3rem;
  background: #f7601f;
}
.pc .g4 .fd3 .item .text {
  font-weight: 400;
  font-size: 2rem;
  color: #ffffff;
  line-height: 3.6rem;
  text-align: left;
  font-style: normal;
}

.pc .g4 .fd4 {
  width: 384px;
  height: 40px;
  font-family: SourceHanSansCN-Normal;
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  top: 1200px;
  left: 80px;
  position: absolute;
}

.pc .g4 .sc4 {
  position: absolute;
  width: calc( 100% - 80px );
  height: 390px;
  top: 1280px;
  left: 80px;
  /* background-color: rgba(255, 255, 255, 0.2); */
}
.pc .g4 .sc4 .swiper-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* .pc .g4 .sc4 .ss1 {
  background-image: url(../assets/limgsen/wonderful-1@2x.png);
}
.pc .g4 .sc4 .ss2 {
  background-image: url(../assets/limgsen/wonderful-2@2x.png);
}
.pc .g4 .sc4 .ss3 {
  background-image: url(../assets/limgsen/wonderful-3@2x.png);
} */

.pc .g4 .swiper2bardiv {
  position: absolute;
  width: 290px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 80px;
  bottom: auto;
  top: 1700px;
}

.pc .g4 .swiper2bardiv .sc2index {
  position: relative;
  width: 20px;
  height: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff !important;
  line-height: 24px;
}
.pc .p4 .swiper2bardiv .sc2bardiv {
  position: relative;
  width: 230px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  overflow: hidden;
}
.pc .g4 .swiper2bardiv .sc2bardiv {
  position: relative;
  width: 230px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1);
}
.pc .swiper2bardiv .sc2bardiv .sc2bar {
  position: relative;
  width: 10%;
  height: 2px;
  background: #fff;
}

.pc .group_5 {
  min-height: 1440px !important;
  height: 1440px !important;
  background-color: #ffffff;
}
.pc .g5 {
  height: 1440px !important;
  min-height: 1440px !important;
  background-color: #ffffff;
}
.pc .g5 .g5-back {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 1031px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/limgsen/g5-back.png);
}
.pc .g5 .fd1 {
  width: 144rem;
  left: 80px;
  top: 160px;
  position: absolute;
}
.pc .g5 .fd1 .ptext {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  line-height: 40px;
  text-align: left;
  font-style: normal;
}
.pc .g5 .fd1 .ptext .orange {
  font-size: 30px;
  line-height: 40px;
  color: #f7601f;
}

.pc .g5 .fd2 {
  width: 166.8rem;
  left: 80px;
  top: 380px;
  position: absolute;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 36px;
  text-align: left;
  font-style: normal;
}

.pc .g5 .fd3 {
  width: 117.2rem;
  left: 80px;
  top: 680px;
  position: absolute;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pc .g5 .fd3 .item {
  width: 18rem;
  height: 22.9rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.pc .g5 .fd3 .item .icon {
  width: 18rem;
  height: 18rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pc .g5 .fd3 .item .icon1 {
  background-image: url(../assets/limgsen/g5_icon1.png);
}
.pc .g5 .fd3 .item .icon2 {
  background-image: url(../assets/limgsen/g5_icon2.png);
}
.pc .g5 .fd3 .item .icon3 {
  background-image: url(../assets/limgsen/g5_icon3.png);
}
.pc .g5 .fd3 .item .icon4 {
  background-image: url(../assets/limgsen/g5_icon4.png);
}
.pc .g5 .fd3 .item .icon5 {
  background-image: url(../assets/limgsen/g5_icon5.png);
}

.pc .g5 .fd3 .item .it {
  font-weight: 500;
  font-size: 2.6rem;
  color: #ffffff;
  line-height: 1;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  background-color: #354627;
}

.pc .g5 .fd4 {
  width: 192rem;
  height: 232px;
  left: 0px;
  top: 1150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/limgsen/g5-btitle.png);
  position: absolute;
}

.pc .group_6 {
  min-height: 320px !important;
  height: 320px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e6dc !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
  -webkit-mask-image: none;
}
.pc .group_6 .g6 {
  position: absolute;

  min-height: 320px !important;
  height: 320px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e6dc !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.pc .group_6 .entitle {
  color: #000000;
  margin: 0px;
  margin-bottom: 30px;
}
.pc .group_6 .p6download {
  width: 240px;
  height: 64px;
  background: #f7601f;
  border-radius: 32px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ffffff;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  cursor: pointer !important;
}

.page {
  /* display: table; */
  height: auto !important;
}

.downloaddiv {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.downloaddiv .downloadlist {
  width: 128rem;
  height: 80rem;
  background-color: #f9f9f9;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.downloaddiv .downloadlist .title {
  font-weight: 400;
  font-size: 24px;
  margin-top: 3rem;
  color: #000000;
  line-height: 2;
  text-align: justify;
  font-style: normal;
  width: 116rem;
  border-bottom: 3px solid #343434;
}
.downloaddiv .downloadlist .list {
  position: relative;
  width: 116rem;
  height: calc(100% - 120px - 2rem);
  /* background-color: rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}
.downloaddiv .downloadlist .item {
  position: relative;
  width: 100%;
  line-height: 8rem;
  font-family: "SourceHanSansCN-Medium";
  /* background-color: rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 2.6rem;
  color: #000000;
  text-align: justify;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer !important;
}
.downloaddiv .downloadlist .downicon {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  line-height: 8rem;
  margin-right: 2rem;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/limgsen/download.png);
}
.downloaddiv .close {
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
  right: 3rem;
  top: 3rem;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/limgsen/close.png);
}

body {
  height: auto !important;
}
@media screen and (min-width: 0px) and (max-width: 750px) {
  .pc .group_2 {
    height: 1340px;
  }
  .pc .group_2 .group_2_in {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  .pc .index .group_2_1 {
    width: 100%;
  }
  .pc .group_1 .mkdots {
    width: 750px;
  }
  .pc .group_2 .group_2_in {
    position: relative;
    z-index: 9;
    width: calc(100% - 80px);
  }
  .pc .index .group_2_2 {
    width: 100%;
    height: 400px;
  }
  .pc .group_2_2 .text_12 {
    top: 200px !important;
    width: 670px !important;
    height: auto !important;
  }
  .pc .group_2_1 .textitem[data-v-727c0f58] {
    width: 670px;
  }
  .pc .index .group_2 .backimg {
    background-position: bottom right;
  }
  .pc .group_2_2 .text_12 {
    top: 230rem;
  }
  .pc .group_2_2 .text_12 img {
    width: 100% !important;
    height: auto !important;
  }
  .pc .index .group_2 .backimg {
    background-position: bottom right !important;
  }
  .pc .entitle {
    font-size: 52px;
    color: #ffffff;
    line-height: 1;
    margin-left: 40px;
    margin-top: 40px;
  }
  .pc .g3 .fd1 {
    width: 670px !important;
    top: 160px;
    left: 40px;
  }
  .pc .g3 .fd2[data-v-727c0f58] {
    width: 670px;
    height: 101rem;
    top: 420px;
    right: 40px;
  }
  .pc .g3 .fdi0 {
    width: 90rem;
    height: 60rem;
  }
  .pc .g3 .fdi1 {
    width: 55rem !important;
    height: 41rem !important;
    margin-top: 10rem;
  }
  .pc .g3 .fdi2 {
    width: 55rem !important;
    height: 41rem !important;
    margin-top: 10rem;
  }
  .pc .g3 .fdi2[data-v-727c0f58] {
    bottom: 0px;
    left: 57rem;
    width: 55rem;
    height: 40rem;
  }

  .pc .g3 .fd3 {
    /* top: 950px;
    left: 230px;
    transform: scale(2.1); */
    top: 1150px;
    left: 35px;
    /* zoom: 2.1; */
    width: 174rem;
    height: 62rem;
  }
  .pc .fd3 .logo {
    width: 40rem;
    height: 18rem;
  }

  .pc .g3 .fd4 {
    width: 670px;
    top: 900px;
    right: 40px;
  }

  .pc .g3 .fd5 {
    width: 670px;
    top: 1480px;
    left: 40px;
  }
  .pc .g3 .fd6 {
    width: 166.4rem;
    height: 93.6rem;
    top: 1730px;
    right: 55px;
    /* zoom: 2; */
  }

  .pc .fdi4 {
    width: 64rem;
    height: 64rem;
    right: 46rem;
    top: 28rem;
  }

  .pc .fdi3 {
    width: 50.4rem;
    height: 50.4rem;
    top: 5rem;
  }
  .pc .fdi5 {
    top: 0px;
    right: 0rem;
    width: 40rem;
    height: 40rem;
  }

  .pc .group_4 .g4 .fd1 {
    width: 670px;
    left: 40px;
    top: 140px;
  }

  .pc .group_3 {
    height: 2220px;
  }

  .pc .g4 .fd3 {
    /* width: 360px;
    top: 700px;
    left: 200px;
    transform: scale(1.7); */
    width: 670px;
    height: auto !important;
    top: 650px;
    left: 40px;
    flex-direction: column;
    /* zoom: 1.7; */
  }
  .pc .g4 .fd3 .st {
    width: 670px;
    height: 42px;
  }
  .pc .g4 .fd3 .list {
    position: relative;
    width: 670px;
    padding-right: 0px;
    height: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    overflow-y: hidden;
  }

  .pc .g4 .fd3 .item {
    position: relative;
    width: 210px;
    height: 308px;
    border-right: 4px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-left: 28px;
    padding-top: 35px;
    margin-top: 40px;
  }
  .pc .g4 .fd3 .item .t {
    font-weight: 800;
    font-size: 53.2px;
    color: #ffffff;
    line-height: 47.6px;
    margin-bottom: 16.8px;
    text-align: left;
    font-family: "SourceHanSansCN-Normal";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.8;
  }

  .pc .g4 .fd3 .item .orgline {
    width: 42px;
    height: 5.6px;
    margin-bottom: 21px;
    background: #f7601f;
  }
  .pc .g4 .fd3 .item .text {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 25.2px;
    text-align: left;
    font-style: normal;
  }

  .pc .g4 .fd2 {
    transform: rotate(0deg);
    top: 0px;
    left: 0px;
    width: 670px;
    height: 42px;
    border-top: none;
    border-bottom: 2px solid #f7601f;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
  }

  .pc .g4 .fd2 img {
    width: 20px;
  }

  .pc .g4 .fd4 {
    width: 384px;
    height: 40px;
    font-family: SourceHanSansCN-Normal;
    font-weight: bold;
    font-size: 40px;
    color: #ffffff;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    top: 1800px;
    left: 40px;
    position: absolute;
  }
  .pc .g4 {
    height: 2420px !important;
    min-height: 1920px !important;
    background-color: #2a381b;
  }

  .pc .g4 .sc4 {
    position: absolute;
    width: 670px;
    height: 390px;
    top: 1880px;
    left: 40px;
  }
  .pc .g4 .swiper2bardiv {
    top: 2300px;
    margin-left: 40px;
  }

  .pc .g5 .entitle {
    right: 40px;
  }
  .pc .g5 .fd1 {
    width: 670px;
    left: 40px;
    top: 140px;
    position: absolute;
  }

  .pc .g5 .fd2 {
    width: 670px;
    left: 40px;
    top: 480px;
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    line-height: 36px;
    text-align: left;
    font-style: normal;
  }
  .pc .g5 .fd3 {
    width: 670px;
    left: 40px;
    top: 1000px;
    position: absolute;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .pc .g5 .fd3 .item {
    width: 126px;
    height: 161px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .pc .g5 .fd3 .item .it {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 1;
    text-align: center;
    font-style: normal;
    text-transform: uppercase;
    background-color: #354627;
    margin-top: 20px;
  }
  .pc .g5 .fd3 .item .icon {
    width: 126px;
    height: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .pc .g5 .fd4[data-v-727c0f58] {
    top: 1200px;
  }
  .downloaddiv .downloadlist[data-v-727c0f58] {
    width: 80%;
    height: 80%;
    background-color: #fff;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .downloaddiv .downloadlist .title {
    width: 500px;
  }
  .downloaddiv .downloadlist .list {
    width: 500px;
  }
  .downloaddiv .downloadlist .list .item {
    font-size: 24px;
    line-height: 82px;
    width: 100%;
    overflow: hidden;
  }
  .downloaddiv .downloadlist .list .item .t {
    width: 90%;
    overflow: hidden;
  }
  .downloaddiv .downloadlist .downicon {
    width: 28px;
    height: 28px;
  }
  .downloaddiv .close {
    width: 48px;
    height: 48px;
    right: 30px;
    top: 30px;
  }

  .pc .g4 .fd2 img {
    width: 30px !important;
    height: 30px !important;
    transform: rotate(90deg);
  }

  .mobile .group_8 .backimg {
    background-size: 240% !important;
    background-repeat: no-repeat;
    background-position: 50% 100% !important;
  }
  .mobile .group_8 {
    position: relative !important;
  }
  .mobile .g8 .g8banner {
    top: 220px;
  }
  .mobile .g8 .textarea {
    top: 30vh !important;
  }
}
</style>



<style src="../assets/css/common.css" />
<style src="../assets/css/indexl.css" />
<style src="../assets/css/indexl1280.css" />
<style src="../assets/css/indexlxg.css" />

<style src="../assets/css/indexlm.css" />
<style src="../assets/css/indexlmxg.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
