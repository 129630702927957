<template>
  <div
    class="group_8 flex-col windowheight"
    :style="`-webkit-mask-position: ${g8top}px ${g8left}px;`"
  >
    <div class="g8 flex-col">
      <div class="g8in flex-col">
        <div class="g8logo">
          <img
          @click="goIndex"
            class="w-logo-img"
            referrerpolicy="no-referrer"
            src="../assets/limgs/logo.png"
          />
        </div>
        <div class="g8banner flex-row" style="margin-top:8vh;">
          <div
              class="titem"
              style="
                font-weight: 500;
                font-size: 26px;
                color: #ffffff;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                margin-bottom: 32px;
              "
            >
              中文简体
            </div>
            <div
              class="titem"
              style="
                font-weight: 500;
                font-size: 26px;
                color: #ffffff;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                margin-bottom: 32px;
              "
            >
              English
            </div>

          <!-- <template v-for="(item, index) in blinks">
            <div class="blist flex-col" :key="index">
              <div class="titem" @click="goSelect(item.id)">
                {{ item.title }}
              </div>

              <div class="bil">
                <template v-for="(item2, index2) in item.list">
                  <div
                    class="bitem"
                    @click="goSelectb(item.id, item2.id)"
                    :key="index2"
                  >
                    {{ item2.title }}
                  </div>
                </template>
              </div>
            </div>
          </template> -->
        </div>

        <!-- <div class="textarea" style="right: 80px">
          <img
            class="image_505"
            referrerpolicy="no-referrer"
            style="width: 50rem; height: 1px; margin: 0px 0 0 0; opacity: .5;"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng378c9d7e85ae34a74b059b6e2ca95f758374832a11efecbf119443472d4a2b08"
          />
          <div class="tat" style="margin-top:0px;">
            想获取更多户外资讯&nbsp;&nbsp;了解我们趣野人？&nbsp;&nbsp;跟我们一起参与活动？&nbsp;&nbsp;马上扫码&nbsp;公众号&nbsp;/&nbsp;小红书&nbsp;/&nbsp;社群二维码，加入我们趣野人阵列！
          </div>
          <div class="qrdiv">
            <div class="qritem">
              <img src="https://cdn.moheweb.com/vdb/lweb/imgs/qr0.png" class="qr0" />
              <span>趣野公众号</span>
            </div>
            <div class="qritem">
              <img src="https://cdn.moheweb.com/vdb/lweb/imgs/qr1.png" class="qr0" />
              <span>趣野社群</span>
            </div>
            <div class="qritem">
              <img src="https://cdn.moheweb.com/vdb/lweb/imgs/qr2.png" class="qr0" />
              <span>趣野小红书</span>
            </div>
          </div>
        </div> -->
        <div class="textarea" style="right: 24rem; width: 500px; top:30vh;">
          <img
            class="image_505"
            referrerpolicy="no-referrer"
            style="width: 88rem; height: 1px; margin: 0px 0 0 0; opacity: 0.5"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng378c9d7e85ae34a74b059b6e2ca95f758374832a11efecbf119443472d4a2b08"
          />
          <div
            class="tat"
            style="
              width: 500px;
              font-family: SourceHanSansCN-Normal, SourceHanSansCN-Normal;
              font-weight: 400;
              font-size: 18px;
              color: #ffffff;
              line-height: 30px;
              text-align: left;
              font-style: normal;
            "
          >
            Want more outdoor information?<br />
            Know us funny wildlings?<br />
            Join us for an event?<br />
            Immediately scan the code Xiaohongshu / Public number / Community QR
            code, join our fun savage array!
          </div>
          <div class="qrdiv" style="margin-top:30px;">
            <div class="qritem">
              <img
                src="https://cdn.moheweb.com/vdb/lweb/imgs/qr2.png"
                class="qr0"
              />
              <span style="opacity: .8">Xiaohongshu</span>
            </div>
            <div class="qritem">
              <img
                src="https://cdn.moheweb.com/vdb/lweb/imgs/qr0.png"
                class="qr0"
              />
              <span style="opacity: .8">Public number</span>
            </div>
            <div class="qritem">
              <img
                src="https://cdn.moheweb.com/vdb/lweb/imgs/qr1.png"
                class="qr0"
              />
              <span style="opacity: .8">Community</span>
            </div>
          </div>
        </div>


        <div class="g8bigtitle"></div>
      </div>
      <div class="backimg"></div>

    <div class="g8blan">
      <div class="textline">
        邮箱：yami.hu@all-fields.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="textline">
        <!-- <span style="opacity: 0">隐私政策</span
        ><span style="margin-left: 27px; opacity: 0">服务条款</span> -->
        <span>沪ICP备2020037860号-2</span>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FooterA",
  props: {
    msg: String,
    kind: String,
    g8top: {
      type: Number,
      default: 10000,
    },
    g8left: {
      type: Number,
      default: 10000,
    },
    lag: {
      type: String,
      default: "",
    },
  },
  watch: {
    // lag: function(newVal, oldVal) {
    // 	this.tlag = newVal; //这样就能在method使用props
    // 	console.log('Watch：', this.tlag)
    // }
  },
  data() {
    return {
      // tlag:'',
      blinks: [
        {
          id: "0",
          title: "趣野风向",
          url: "",
          class: "jianjie",
          list: [
            {
              title: "FM.看看世界",
              des: "了解趣野历史相关活动",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0bbd5b52752e40d7bd05e634f2ff35a3_mergeImage.png",
              id: "00",
            },
            {
              title: "趣野故事会",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfde342438c247fab8fca0fa5adc4e4889cb2b837a5debc0f0799d0f0f5d3c4c8",
              id: "01",
            },
            {
              title: "趣野视角",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng76c7ee71154fce1d4550cbe476a1aa62bd0a0fa0efff11576097faa2e329bc11",
              id: "02",
            },
          ],
        },
        {
          id: "2",
          title: "趣野好物",
          url: "",
          class: "fuwuneirong",
          list: [
            {
              title: "好物推荐",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "20",
            },
            // {
            //   title: "购物平台",
            //   des: "了解更多",
            //   url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
            //   id: "21",
            // },
          ],
        },
        {
          id: "3",
          title: "趣野SHOW",
          url: "",
          class: "anli",
          list: [
            {
              title: "现场SHOW什么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "30",
            },
            {
              title: "新鲜SHOW闻",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/efed9ea5f9d94fe999ad67d5274954b7_mergeImage.png",
              id: "31",
            },
            {
              title: "想来SHOW一下么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f5f6ac4ceb9e488f9a8a4bde7d2b358f_mergeImage.png",
              id: "32",
            },
            {
              title: "Ta们在SHOW",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cc8ff4aedae34e61931b49962afb8a2c_mergeImage.png",
              id: "32",
            },
          ],
        },
        {
          id: "4",
          title: "趣野CLUB",
          url: "",
          class: "lianxiwomen",
          list: [
            {
              title: "趣野人集合",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "40",
            },
            {
              title: "精彩回放",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "41",
            },
          ],
        },
        {
          id: "1",
          title: "趣野伙伴",
          url: "",
          class: "huoban",
          list: [
            {
              title: "先锋玩家",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "10",
            },
            {
              title: "合作品牌",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "11",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {

    goIndex() {
        this.$router.push(`/indexm`);
      // if (this.kind == "index") {
      //   location.reload();
      // } else {
      //   this.$router.push(`/indexm`);
      // }
    },
    goSelect(id) {
      // this.$router.push(`/sindex/${index}/${this.lag}`);

      let url = "";
      if (id == 0) {
        url = "fengxiangm";
      }
      if (id == 1) {
        url = "huobanm";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "showm";
      }
      if (id == 4) {
        url = "clubm";
      }

      console.log("url:", url);

      if (url != "") {
        this.$router.push(`/${url}/0/${this.lag}`);
      }
    },
    goSelectb(id, id2) {
      let url = "";
      if (id == 0) {
        url = "fengxiangm";
      }
      if (id == 1) {
        url = "huobanm";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "showm";
      }
      if (id == 4) {
        url = "clubm";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }

      // this.$router.push(`/sindex/${index}/${this.lag}`);
    },
  },
};
</script>

<style scoped>
</style>
